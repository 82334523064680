import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@environment/environment';
import {
  ModelSearchResult,
  CountryCodeLookupModel,
  ProfilesModel,
  CountryCodeLookup, StateCodeLookup, StateCodeLookupModel
} from '../models/model.userProfileSearch';
@Injectable({
  providedIn: 'root',
})
export class UserProfileSearchReultService {
  constructor(private http: HttpClient) { }

  list: ProfilesModel;
  listUserProfileSearchResData: ProfilesModel[];
  countryData: CountryCodeLookup;
  statedata: StateCodeLookup;
  customFlag: any;
  totalCount: number;
  profileName: string;
  primaryEmail: string;
  getUserProfileData() {
    return this.list;
  }
  setUserProfileData(data: ProfilesModel) {
    this.list = data;
  }

  setCustomFlag(data) {
    this.customFlag = data;
  }
  getCustomFlag() {
    return this.customFlag;
  }
  setTotalCount(data) {
    this.totalCount = data;
  }
  getTotalCount() {
    return this.totalCount;
  }

  getUserProfileSearchResData() {
    return this.listUserProfileSearchResData;
  }
  setUserProfileSearchResData(data: ProfilesModel[]) {
    this.listUserProfileSearchResData = data;
  }

  setCountryCdLookup(data: CountryCodeLookup) {
    this.countryData = data;
  }
  getCountryCdLookup() {
    return this.countryData;
  }
  setStateCdLookup(data: StateCodeLookup) {
    this.statedata = data;
  }
  getStateCdLookup() {
    return this.statedata;
  }
  setProfileName(data: string) {
    this.profileName = data;
  }
  getProfileName() {
    return this.profileName;
  }
  setPrimaryEmail(data: string) {
    this.primaryEmail = data;
  }
  getPrimaryEmail() {
    return this.primaryEmail;
  }
  getCountryData(): Observable<CountryCodeLookupModel> {
    const url2 = environment.appUrl + `lookups/CountryCdLookup`;
    return this.http.get<CountryCodeLookupModel>(url2).pipe(
      map((events) => {
        return events;
      })
    );
  }
  getStateData(countryCodeId): Observable<StateCodeLookupModel> {
    return this.http
      .get<any>(environment.appUrl + 'lookups/StateCdLookup', {
        params: countryCodeId,
      })
      .pipe(
        map((events) => {
          return events;
        })
      );
  }
  getSearchResults(
    requestPayload,
    isNewFlag,
    actionType = 'searchProfileList',
    page: number = 0,
    pageSize: number = 10,
    order: string = ''
  ): Observable<any> {
    // TODO:need proper type def
    const url = environment.appUrl + `profiles`; // TODO: ENV specific
    if (requestPayload.mdmId && requestPayload.mdmId.length) {
      const url2 = environment.appUrl + `profiles/${requestPayload.mdmId}?isNew=${isNewFlag}&actionType=${actionType}`;
      return this.http.get<any>(url2).pipe(
        map((events) => {
          return events;
        })
      );
    } else {
      requestPayload = Object.assign({
        isNew: isNewFlag
      }, requestPayload);
      return this.http
        .get<any>(`${url}?actionType=${actionType}&page=${page}&pageSize=${pageSize}&order=${order}`, { params: requestPayload })
        .pipe(
          map((events) => {
            return events;
          })
        );
    }
  }
  insertCreateProfileData(requestPayload): Observable<any> {
    const url = environment.appUrl + `profiles`;
    return this.http
      .post<any>(url, requestPayload)
      .pipe(
        map((events) => {
          return events;
        })
      );

  }
  getPreferenceCategory() {
    return this.http
      .get<any>(environment.appUrl + 'lookups/PreferenceCatLookup')
      .pipe(
        map((events) => {
          return events;
        })
      );
  }

  getPreferenceByCategoryId(prefCatId) {
    return this.http
      .get<any>(environment.appUrl + 'lookups/PreferenceCdLookup', {
        params: prefCatId,
      })
      .pipe(
        map((events) => {
          return events;
        })
      );
  }

  updatePreference(profileDetails) {
    return this.http
      .put<any>(environment.appUrl + 'profiles', profileDetails)
      .pipe(
        map((events) => {
          return events;
        })
      );
  }

  saveProfilePhoto(profileDetails) {
    return this.http
      .post<any>(environment.appUrl + 'profiles/photo-upload', profileDetails)
      .pipe(
        map((events) => {
          return events;
        })
      );
  }
}
