import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GcpwebappAuthGuard } from '@shared/shared-services/auth/gcpwebapp-auth.guard';
import { UnauthorizeComponent } from './unauthorize/unauthorize.component';

const routes: Routes = [
  {
    path: 'unauth',
    component: UnauthorizeComponent,
  },
  {
    path: '',
    canLoad: [GcpwebappAuthGuard],
    // canActivate: [GcpwebappAuthGuard],
    loadChildren: () =>
      import('./gcp-user-profile/gcp-user-profile.module').then(
        (m) => m.GcpUserProfileModule
      ),
  },
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
