import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Auth } from 'aws-amplify';

@Component({
  selector: 'app-unauthorize',
  templateUrl: './unauthorize.component.html',
  styleUrls: ['./unauthorize.component.scss'],
})
export class UnauthorizeComponent implements OnInit {
  errorAuth: any;
  errorDesc: string;
  constructor(private activatedroute: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.errorAuth = history.state;
    if (!this.errorAuth.error) {
      this.router.navigate(['/']);
    }
    this.errorDesc = decodeURIComponent(
      (this.errorAuth.error + '').replace(/\+/g, '%20')
    );
  }
  authSignout() {
    Auth.signOut();
  }
}
