// const awsConfig = {
//   aws_project_region: 'us-east-1',
//   //   aws_cognito_identity_pool_id:
//   //     'us-east-1:d4e568c8-eb57-4f90-9b27-7bc4faeb7667',
//   aws_cognito_region: 'us-east-1',
//   aws_user_pools_id: 'us-east-1_GMLJmCi6C',
//   aws_user_pools_web_client_id: '7e9mv38kud08jqr6bo1fstnuap',
//   oauth: {
//     domain: 'gcpwa-dev-local.auth.us-east-1.amazoncognito.com',
//     scope: ['email', 'profile', 'openid'],
//     redirectSignIn: 'https://d2frsh72qdvr89.cloudfront.net', //'http://localhost:4200',
//     redirectSignOut: 'https://d2frsh72qdvr89.cloudfront.net', //'http://localhost:4200',
//     responseType: 'token', // or 'token', note that REFRESH token will only be generated when the responseType is code
//   },
// };
import { environment } from '@environment/environment';
const awsConfig = {
  aws_project_region: environment.aws_project_region,
  aws_cognito_region: environment.aws_cognito_region,
  aws_user_pools_id: environment.aws_user_pools_id,
  aws_user_pools_web_client_id: environment.aws_user_pools_web_client_id,
  oauth: {
    domain: environment.oauth_domain,
    identityProvider: environment.aws_user_pools_identity_provider,
    scope: ['email', 'profile', 'openid'],
    redirectSignIn: environment.oauth_redirectSignIn,
    redirectSignOut: environment.oauth_redirectSignOut,
    responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
  },
};
export default awsConfig;
