import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { from, Observable, throwError } from 'rxjs';

import { Auth } from 'aws-amplify';
import { catchError, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class GcpWebAppInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    let heades = request.headers;
    let authorizedRequest: any = request;
    return from(Auth.currentSession()).pipe(
      switchMap((token) => {
        // Check if 'X-Amz-Algorithm' or 'Signature' query parameters are present
        const hasAmzAlgorithm = request.urlWithParams.includes('X-Amz-Algorithm');
        const hasSignature = request.urlWithParams.includes('Signature');

        // Clone the request to modify the headers if necessary
        let clonedRequest = request.clone();

        // Remove the 'Authorization' header if other auth mechanisms are present
        if ((hasAmzAlgorithm || hasSignature)) {
          clonedRequest = clonedRequest.clone({
            headers: clonedRequest.headers.delete('Authorization')
          });
          // Pass the modified request to the next handler
          return next.handle(clonedRequest).pipe(
            tap((evt) => {
              // console.log(evt);
            }),
            catchError((err: Response) => {
              return throwError(err);
            })
          );
        }


        const idToken = token.getIdToken();
        if (!heades.has('Authorization')) {
          heades = heades.append('Authorization', idToken.getJwtToken());
        }
        authorizedRequest = request.clone({
          headers: heades,
        });
        return next.handle(authorizedRequest).pipe(
          tap((evt) => {
            // console.log(evt);
          }),
          catchError((err: Response) => {
            return throwError(err);
          })
        );
      })
    );
  }
}
