import { Injectable } from '@angular/core';
import { CanActivate, Router, CanLoad } from '@angular/router';
import { environment } from '@environment/environment';
import { Auth } from 'aws-amplify';
import { UtilityService } from '@shared/shared-services/featured/utility.service';

@Injectable({
  providedIn: 'root',
})
export class GcpwebappAuthGuard implements CanLoad, CanActivate {
  hasAccess = false;
  constructor(private router: Router, private utilityService: UtilityService) {
    this.utilityService.userAccessObservable.subscribe((userInfo) => {
      if (!userInfo.groupType) {
        this.hasAccess = false;
      } else {
        this.hasAccess = true;
      }
    });
  }
  canLoad() {
    return this.checkTokenExpiration();
  }

  canActivate() {
    return this.checkTokenExpirationWithAuth();
  }

  checkTokenExpirationWithAuth() {
    return Auth.currentAuthenticatedUser({
      bypassCache: true, // Optional, By default is false.
    })
      .then((user) => {
        if (!this.hasAccess) {
          this.router.navigate(['/check-useraccess']);
          return false;
        }
        return true;
      })
      .catch((err) => {
        console.log(err);
        Auth.federatedSignIn({
          customProvider: environment.aws_user_pools_identity_provider,
        })
          .then((cred) => {
            // If success, you will get the AWS credentials
            console.log(cred);
            // return Auth.currentAuthenticatedUser();
          })
          .then((user) => {
            // If success, the user object you passed in Auth.federatedSignIn
            console.log(user);
          })
          .catch((e) => {
            console.log(e);
          });
        return false;
      });
  }

  checkTokenExpiration() {
    // this.router.events.subscribe((event) => {
    return Auth.currentAuthenticatedUser({
      bypassCache: true, // Optional, By default is false.
    })
      .then((user) => {
        return true;
      })
      .catch((err) => {
        console.log(err);
        Auth.federatedSignIn({
          customProvider: environment.aws_user_pools_identity_provider,
        })
          .then((cred) => {
            // If success, you will get the AWS credentials
            console.log(cred);
            // return Auth.currentAuthenticatedUser();
          })
          .then((user) => {
            // If success, the user object you passed in Auth.federatedSignIn
            console.log(user);
          })
          .catch((e) => {
            console.log(e);
          });
        return false;
      });
    // });
    // return true;
  }
}
