import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from '@environment/environment';
import { Auth } from 'aws-amplify';
import { Subject } from 'rxjs/internal/Subject';
import { ProfilesModel } from 'src/app/gcp-user-profile/models/model.userProfileSearch';
import { UtilityService } from './utility.service';
import { UserProfileSearchReultService } from 'src/app/gcp-user-profile/services/user-profile-search-reult.service';
import { Router } from '@angular/router';
import { ProfileFuzzyMatch } from 'src/app/gcp-user-profile/models/user-merge-details.model';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  searchData: BehaviorSubject<any>;
  searchPayload: BehaviorSubject<any>;
  searchResultData: BehaviorSubject<any>;
  searchProfileMergeResultData: BehaviorSubject<any>;
  profileData: BehaviorSubject<any>;
  isLoaded: BehaviorSubject<any>;
  userinfo: BehaviorSubject<any>;
  profileDetailsData: BehaviorSubject<any>;
  mergeProfileDetailsData: BehaviorSubject<any>;
  stopedit: BehaviorSubject<boolean>;
  formValueChanged: BehaviorSubject<boolean>;
  section: BehaviorSubject<object>;
  relationCRMMainProfile: BehaviorSubject<string>;
  relationMainName: BehaviorSubject<string>;
  isRelationProfileClicked: BehaviorSubject<boolean>;
  isNew = 'N';
  isFuzzyMerged: BehaviorSubject<boolean>;
  isProfileFuzzyMerged: BehaviorSubject<boolean>;
  isProfileMerged: BehaviorSubject<boolean>;
  isMatchPairRejected: BehaviorSubject<boolean>;
  isProfileMatchPairRejected: BehaviorSubject<boolean>;
  fuzzyMatchData: BehaviorSubject<ProfileFuzzyMatch[]>;

  constructor(
    private http: HttpClient,
    private utilService: UtilityService,
    private userProfileSearchReultService: UserProfileSearchReultService,
    private router: Router
  ) {
    this.searchData = new BehaviorSubject(this.searchData);
    this.searchPayload = new BehaviorSubject(this.searchPayload);
    this.profileData = new BehaviorSubject(this.profileData);
    this.isLoaded = new BehaviorSubject(this.isLoaded);
    this.userinfo = new BehaviorSubject(this.userinfo);
    this.searchResultData = new BehaviorSubject(this.searchResultData);
    this.profileDetailsData = new BehaviorSubject(this.profileDetailsData);
    this.mergeProfileDetailsData = new BehaviorSubject(this.mergeProfileDetailsData);
    this.stopedit = new BehaviorSubject(true);
    this.formValueChanged = new BehaviorSubject(false);
    this.section = new BehaviorSubject({
      url: '',
      param: '',
    });
    this.relationCRMMainProfile = new BehaviorSubject('');
    this.isRelationProfileClicked = new BehaviorSubject(false);
    this.relationMainName = new BehaviorSubject('');
    this.fuzzyMatchData = new BehaviorSubject([]);
    this.searchProfileMergeResultData = new BehaviorSubject(this.searchProfileMergeResultData);

    this.isFuzzyMerged = new BehaviorSubject(false);
    this.isProfileMerged = new BehaviorSubject(false);
    this.isProfileFuzzyMerged = new BehaviorSubject(false);
    this.isMatchPairRejected = new BehaviorSubject(false);
    this.isProfileMatchPairRejected = new BehaviorSubject(false);

  }

  fetchSearchData(): Observable<string> {
    return this.searchData.asObservable();
  }
  fetchSearchPayload(): Observable<string> {
    return this.searchPayload.asObservable();
  }
  fetchData(): Observable<string> {
    return this.profileData.asObservable();
  }
  fetchSearchResultData(): Observable<any> {
    return this.searchResultData.asObservable();
  }
  fetchProfileData(): Observable<any> {
    return this.profileDetailsData.asObservable();
  }
  fetchMergeProfileData(): Observable<any> {
    return this.mergeProfileDetailsData.asObservable();
  }
  fetchStopEdit(): Observable<any> {
    return this.stopedit.asObservable();
  }

  sendStopEdit(data) {
    this.stopedit.next(data);
  }
  sendSearchData(data) {
    this.searchData.next(data);
  }
  sendSearchPayload(data) {
    this.searchPayload.next(data);
  }
  sendSearchResultData(data) {
    this.searchResultData.next(data);
  }

  sendData(data) {
    this.profileData.next(data);
  }
  sendProfileDetailsData(data) {
    this.profileDetailsData.next(data);
  }
  sendMergeProfileDetailsData(data) {
    this.mergeProfileDetailsData.next(data);
  }

  startLoading() {
    this.isLoaded.next(true);
  }

  stopLoading() {
    this.isLoaded.next(false);
  }

  sendUserInfo(user) {
    this.userinfo.next(user);
  }
  fetchUserInfo(): Observable<string> {
    return this.userinfo.asObservable();
  }

  fetchFormValue(): Observable<any> {
    return this.formValueChanged.asObservable();
  }

  sendFormValue(data) {
    this.formValueChanged.next(data);
  }

  fetchRelationProfileClicked(): Observable<boolean> {
    return this.isRelationProfileClicked.asObservable();
  }

  sendRelationProfileClicked(data) {
    this.isRelationProfileClicked.next(data);
  }

  fetchRelationCRMProfileValue(): Observable<string> {
    return this.relationCRMMainProfile.asObservable();
  }

  sendRelationName(data) {
    this.relationMainName.next(data);
  }

  fetchRelationName(): Observable<string> {
    return this.relationMainName.asObservable();
  }

  sendRelationCRMProfileValue(data) {
    this.relationCRMMainProfile.next(data);
  }
  fetchSection(): Observable<any> {
    return this.section.asObservable();
  }

  sendSection(data) {
    this.section.next(data);
  }

  fetchProfileMerged(): Observable<boolean> {
    return this.isProfileMerged.asObservable();
  }

  sendProfileMerged(data: boolean) {
    this.isProfileMerged.next(data);
  }

  fetchProfileFuzzyMerged(): Observable<boolean> {
    return this.isProfileFuzzyMerged.asObservable();
  }

  sendProfileFuzzyMerged(data: boolean) {
    this.isProfileFuzzyMerged.next(data);
  }

  // for general list
  fetchFuzzyMerged(): Observable<boolean> {
    return this.isFuzzyMerged.asObservable();
  }

  sendFuzzyMerged(data: boolean) {
    this.isFuzzyMerged.next(data);
  }

  fetchProfileMatchPairRejected(): Observable<boolean> {
    return this.isProfileMatchPairRejected.asObservable();
  }

  sendProfileMatchPairRejected(data: boolean) {
    this.isProfileMatchPairRejected.next(data);
  }

  fetchMatchPairRejected(): Observable<boolean> {
    return this.isMatchPairRejected.asObservable();
  }

  sendMatchPairRejected(data: boolean) {
    this.isMatchPairRejected.next(data);
  }

  fetchSearchProfileMergeResultData(): Observable<any> {
    return this.searchProfileMergeResultData.asObservable();
  }

  sendSearchProfileMergeResultData(data) {
    this.searchProfileMergeResultData.next(data);
  }

  getUserInfo() {
    return from(Auth.currentSession()).pipe(
      switchMap((token) => {
        const accessToken = token.getAccessToken();
        const headers = new HttpHeaders({
          Authorization: accessToken.getJwtToken(),
        });
        return this.http
          .get<any>(environment.appUrl + 'userInfo', { headers }) // { headers }
          .pipe(
            map((user) => {
              return user;
            })
          );
      })
    );
  }

  public getErrorJson() {
    return this.http.get('../../../../assets/data/resourse.json').pipe(
      map((data) => {
        return data;
      })
    );
  }

  getProfileInfo(mdmIdValue) {
    let payload = {
      firstName: null,
      lastName: null,
      email: null,
      stateCode: '',
      countryCode: '',
      mdmId: mdmIdValue,
    };
    payload = this.utilService.removeNullObject(payload);
    const customFlag = this.userProfileSearchReultService.getCustomFlag();
    this.userProfileSearchReultService
      .getSearchResults(payload, this.isNew)
      .subscribe(
        (data) => {
          this.utilService.backupProfile = data.Profile;
          this.userProfileSearchReultService.setUserProfileData(data.Profile);
          this.sendProfileDetailsData(data.Profile);
          this.sendData(data.Profile);
          this.userProfileSearchReultService.setCustomFlag({
            searchPageFlag: customFlag?.searchPageFlag,
            isNew: this.isNew,
          });
          this.router.navigate(['/profile-details', mdmIdValue.trim()]);
        },
        (error) => {
          this.utilService.handleError(error);
        }
      );
  }

  getMergeProfileInfo(mdmId) {
    const payload = {
      mdmId: mdmId.toString()
    };
    this.userProfileSearchReultService
      .getSearchResults(payload, 'N', 'MatchAndMerge')
      .subscribe(
        (data) => {
          this.sendMergeProfileDetailsData(data.Profile);
        },
        (error) => {
          this.utilService.handleError(error);
          this.sendMergeProfileDetailsData(null);
        }
      );
  }
}
