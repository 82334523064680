import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { CommonService } from '../featured/common.service';
import { finalize } from 'rxjs/internal/operators/finalize';

@Injectable()
export class LoaderInterceptorInterceptor implements HttpInterceptor {
  activeRequests = 0;

  constructor(private commonService: CommonService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.activeRequests === 0) {
      this.commonService.startLoading();
    }

    this.activeRequests++;

    return next.handle(request).pipe(
      finalize(() => {
        this.activeRequests--;
        if (this.activeRequests === 0) {
          this.commonService.stopLoading();
        }
      })
    );
  }
}
