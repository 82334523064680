import { Component, OnInit } from '@angular/core';
import Amplify, { Auth, Hub } from 'aws-amplify';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { UtilityService } from '@shared/shared-services/featured/utility.service';
import awsConfig from 'src/aws-exports';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Rosewood GuestView';
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  idleTime: any;
  idleTimeOut: any;

  constructor(
    private idle: Idle,
    private keepalive: Keepalive,
    private utilService: UtilityService,
    private router: Router
  ) {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      if (event.includes('_failure')) {
        console.log(event, data);
        this.router.navigate(['/unauth'], {
          state: { error: decodeURI(data.message.toString()) },
        });
      }
    });
    Amplify.configure(awsConfig);
  }
  ngOnInit() {
    this.utilService.userAccessObservable.subscribe((res) => {
      if (res.timeOut) {
        this.idleTime = res.timeOut - 1;
        this.idleTimeOut = 1;
        this.idleInit();
      }
    });
  }
  idleInit() {
    // sets an idle timeout of 5 seconds, for testing purposes.
    this.idle.setIdle(this.idleTime);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    this.idle.setTimeout(this.idleTimeOut);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => (this.idleState = 'No longer idle.'));
    this.idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      Auth.signOut();
    });
    this.idle.onIdleStart.subscribe(() => {
      this.idleState = 'You should be gone idle!';
      // console.log(this.idleTime, this.idleTimeOut);
    });
    this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!';
      // console.log(this.idleState);
      // console.log(this.idleTime, this.idleTimeOut);
    });

    // sets the ping interval to 15 seconds
    this.keepalive.interval(15);

    this.keepalive.onPing.subscribe(() => (this.lastPing = new Date()));

    this.reset();
  }
  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
    // console.log(this.idleTime, this.idleTimeOut);
    // console.log(this.idleState);
  }
}
