<div class="container-fluid sticky-padding">
    <div class="site-wrapper">
        <div class="header-content">
            <div class="row custom-header-container sticky">
                <div class="col-lg-7 col-md-5 col-sm-5 d-flex">
                    <div class="row">
                        <div class="col-sm-9">
                            <a class="logo-text">Rosewood GuestView</a>
                        </div>
                    </div>         
                </div>
            </div>
        </div>
        <p class="unauthorized">Login has failed with following error</p>
        <p class="unauthorized">{{errorDesc}}</p>
    </div>
</div>

