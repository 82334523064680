export const environment = {
  production: true,
  aws_project_region: 'us-east-1',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_M2Ye2e7d6',
  aws_user_pools_identity_provider: 'RosewoodAzureADPROD',
  aws_user_pools_web_client_id: 'b3hj95284tetb091cq0g74rq7',
  oauth_domain: 'rw-gcp-mw-wa-prod.auth.us-east-1.amazoncognito.com',
  oauth_redirectSignIn: 'https://guestview.rosewoodhotels.com',
  oauth_redirectSignOut: 'https://guestview.rosewoodhotels.com',
  baseUrl: 'https://rw-gcp-mw-wa-prod.auth.us-east-1.amazoncognito.com/',
  appUrl: 'https://globetrotterapi.rosewoodhotels.com/PROD/gcp/wa/',
};
