import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '@environment/environment';

import {
  ErrorHttpObj,
  ErrorHttpStatic,
} from '../../../gcp-user-profile/models/model.commonType';
import * as _ from 'lodash';
import { UIPermission, UserPermission } from 'src/app/gcp-user-profile/models/model.user-permission';
@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  private backupProfileData: any;
  private updatedProfileData: any;
  public userInfoStore: any;
  public staticJsonObjStore: ErrorHttpStatic;
  public miscselectedtab: string;

  public errorStore: BehaviorSubject<ErrorHttpObj> = new BehaviorSubject<ErrorHttpObj>(
    {
      code: '',
      message: '',
    }
  );
  public errorObjectStore: Observable<ErrorHttpObj> = this.errorStore.asObservable();

  public uiErrorStore: BehaviorSubject<any> = new BehaviorSubject<any>('');
  public backupProfileDataStore: BehaviorSubject<any> = new BehaviorSubject<any>(
    {}
  );
  public successStore: BehaviorSubject<any> = new BehaviorSubject<any>({
    message: '',
  });

  public userAccessObservable: BehaviorSubject<any> = new BehaviorSubject<any>(
    {}
  );

  fetchUserAccess(): Observable<any> {
    return this.userAccessObservable.asObservable();
  }

  set errorObject(error: ErrorHttpObj) {
    this.errorStore.next(error);
  }

  set uiErrorMessage(errors: any) {
    this.uiErrorStore.next(errors);
  }

  // get errorObject() {
  //   return this.errorObjectStore;
  // }

  set updateProfile(profile) {
    this.updatedProfileData = profile;
    this.backupProfileDataStore.next(this.updatedProfileData);
  }

  get updateProfile() {
    return this.updatedProfileData;
  }

  set backupProfile(profile) {
    this.backupProfileData = Object.assign({}, profile);
    this.updateProfile = profile;
  }

  get backupProfile() {
    return this.backupProfileData;
  }

  successObject(msg) {
    this.successStore.next({ message: msg });
  }

  set staticJsonObj(obj) {
    this.staticJsonObjStore = obj;
  }

  get staticJsonObj() {
    return this.staticJsonObjStore;
  }

  set userInfo(uInfo) {
    this.userInfoStore = uInfo;
    this.userAccessObservable.next(this.userInfoStore);
  }

  get hasGroupType() {
    return !_.isEmpty(this.userInfoStore?.groupType);
  }

  getPermission(scope: string): any {
    if (_.isEmpty(this.userInfoStore)) {
      return {};
    }
    const permssionList = this.userInfoStore.permissions;
    const permission = _.find(permssionList, { scope });
    return permission;
  }

  hasPermission(scope: string, action: string): boolean {
    if (!this.userInfoStore || _.isEmpty(this.userInfoStore.permissions)) {
      return false;
    }

    const permission: UserPermission = _.find(this.userInfoStore.permissions, { scope });
    if (!permission || !permission.tableOperation) {
      return false;
    }

    const { tableOperation } = permission;

    // tableOperation value: all | read | create | update | delete
    // Check for full permission or specific action permission
    return tableOperation.all || tableOperation[action.toLowerCase()] || false;
  }

  hasReadOnlyPermission(scope: string): boolean {
    if (!this.userInfoStore || _.isEmpty(this.userInfoStore.permissions)) {
      return false;
    }

    const permission: UserPermission = _.find(this.userInfoStore.permissions, { scope });
    if (!permission || !permission.tableOperation) {
      return false;
    }

    const { tableOperation } = permission;

    // tableOperation value: all | read | create | update | delete
    // Check for full permission or specific action permission
    return tableOperation.readOnly || false;
  }

  hasUIPermission(scope: string): boolean {
    if (!this.userInfoStore || _.isEmpty(this.userInfoStore.uiPermissions)) {
      return false;
    }

    const permission: UIPermission = _.find(this.userInfoStore.uiPermissions, { scope });
    if (!permission) {
      return false;
    }

    return permission.value;
  }


  checkPermission(type): boolean {
    // ADMIN has all permission
    if (this.userInfoStore.groupType === 'Admin') {
      return true;
    }

    let check = false;
    switch (type) {
      case 'CEO_EVENT': {
        check = this.userInfoStore.UINoneditablePolicy?.Profile?.Miscellaneous?.CEO_EVENTS?.editable ?? false;
        break;
      }
      case 'PRESIDENT_EVENT': {
        check = this.userInfoStore.UINoneditablePolicy?.Profile?.Miscellaneous?.PRESIDENT_EVENTS?.editable ?? false;
        break;
      }
      default: check = false;
    }
    return check;
  }

  constructor() { }

  public handleError(error): void {
    if (error.error && error.error.code && error.error.message) {
      this.errorObject = error.error;
    } else if (error.error && Array.isArray(error.error)) {
      this.errorObject = error.error;
    } else {
      this.errorObject = { code: '', message: error.message };
    }
  }

  public handleSuccess(msg): void {
    this.successObject = msg;
  }

  removeNullObject(obj) {
    Object.keys(obj).forEach(
      (k) => (obj[k] === null || obj[k] === '') && delete obj[k]
    );
    return obj;
  }

  removeNilObject(obj) {
    Object.keys(obj).forEach(
      (k) => (obj[k] === null || obj[k] === undefined || obj[k] === '') && delete obj[k]
    );
    return obj;
  }

  public sortAlphabetically(list, sorter) {
    return _.orderBy(list, sorter, ['asc']);
  }

  set selectedTab(data) {
    this.miscselectedtab = data;
  }

  get selectedTab() {
    return this.miscselectedtab;
  }

  findLookup(target, lookupList = [], callback = (x) => { }) {
    if (_.isEmpty(target)) {
      return callback(undefined);
    }
    const value = typeof target === 'string' ? target : target?.rowidObject?.trim();
    const record = lookupList.find((item) => item.rowidObject === value);
    callback(record);
  }

  padZero(num, digit = 2) {
    return num.toString().padStart(digit, '0');
  }
}
